export const albums_data = {
   "agrint-dyn-mob": {
      "name": "Agrint Dyn (mob)",
      "photos": [
         {
            "faces": [],
            "height": 1051,
            "name": "Shaiya0017.JPG",
            "slug": "shaiya0017-jpg",
            "src": "/static/_gallery/albums/agrint-dyn-mob/1600x871_shaiya0017-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/agrint-dyn-mob/1024x557_shaiya0017-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/agrint-dyn-mob/1600x871_shaiya0017-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/agrint-dyn-mob/500x272_shaiya0017-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/agrint-dyn-mob/800x435_shaiya0017-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/agrint-dyn-mob/500x272_shaiya0017-jpg.jpg",
            "width": 1930
         },
         {
            "faces": [],
            "height": 1051,
            "name": "Shaiya0019.JPG",
            "slug": "shaiya0019-jpg",
            "src": "/static/_gallery/albums/agrint-dyn-mob/1600x871_shaiya0019-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/agrint-dyn-mob/1024x557_shaiya0019-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/agrint-dyn-mob/1600x871_shaiya0019-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/agrint-dyn-mob/500x272_shaiya0019-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/agrint-dyn-mob/800x435_shaiya0019-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/agrint-dyn-mob/500x272_shaiya0019-jpg.jpg",
            "width": 1930
         },
         {
            "faces": [],
            "height": 1051,
            "name": "Shaiya0021.JPG",
            "slug": "shaiya0021-jpg",
            "src": "/static/_gallery/albums/agrint-dyn-mob/1600x871_shaiya0021-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/agrint-dyn-mob/1024x557_shaiya0021-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/agrint-dyn-mob/1600x871_shaiya0021-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/agrint-dyn-mob/500x272_shaiya0021-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/agrint-dyn-mob/800x435_shaiya0021-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/agrint-dyn-mob/500x272_shaiya0021-jpg.jpg",
            "width": 1930
         }
      ],
      "slug": "agrint-dyn-mob",
      "src": "/static/_gallery/albums/agrint-dyn-mob/500x272_shaiya0017-jpg.jpg"
   },
   "big-horn-deer-mount": {
      "name": "Big Horn Deer (mount)",
      "photos": [
         {
            "faces": [],
            "height": 1051,
            "name": "Shaiya0118.JPG",
            "slug": "shaiya0118-jpg",
            "src": "/static/_gallery/albums/big-horn-deer-mount/1600x871_shaiya0118-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/big-horn-deer-mount/1024x557_shaiya0118-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/big-horn-deer-mount/1600x871_shaiya0118-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/big-horn-deer-mount/500x272_shaiya0118-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/big-horn-deer-mount/800x435_shaiya0118-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/big-horn-deer-mount/500x272_shaiya0118-jpg.jpg",
            "width": 1930
         },
         {
            "faces": [],
            "height": 1051,
            "name": "Shaiya0119.JPG",
            "slug": "shaiya0119-jpg",
            "src": "/static/_gallery/albums/big-horn-deer-mount/1600x871_shaiya0119-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/big-horn-deer-mount/1024x557_shaiya0119-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/big-horn-deer-mount/1600x871_shaiya0119-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/big-horn-deer-mount/500x272_shaiya0119-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/big-horn-deer-mount/800x435_shaiya0119-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/big-horn-deer-mount/500x272_shaiya0119-jpg.jpg",
            "width": 1930
         },
         {
            "faces": [],
            "height": 1051,
            "name": "Shaiya0120.JPG",
            "slug": "shaiya0120-jpg",
            "src": "/static/_gallery/albums/big-horn-deer-mount/1600x871_shaiya0120-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/big-horn-deer-mount/1024x557_shaiya0120-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/big-horn-deer-mount/1600x871_shaiya0120-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/big-horn-deer-mount/500x272_shaiya0120-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/big-horn-deer-mount/800x435_shaiya0120-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/big-horn-deer-mount/500x272_shaiya0120-jpg.jpg",
            "width": 1930
         },
         {
            "faces": [],
            "height": 1051,
            "name": "Shaiya0121.JPG",
            "slug": "shaiya0121-jpg",
            "src": "/static/_gallery/albums/big-horn-deer-mount/1600x871_shaiya0121-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/big-horn-deer-mount/1024x557_shaiya0121-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/big-horn-deer-mount/1600x871_shaiya0121-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/big-horn-deer-mount/500x272_shaiya0121-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/big-horn-deer-mount/800x435_shaiya0121-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/big-horn-deer-mount/500x272_shaiya0121-jpg.jpg",
            "width": 1930
         }
      ],
      "slug": "big-horn-deer-mount",
      "src": "/static/_gallery/albums/big-horn-deer-mount/500x272_shaiya0118-jpg.jpg"
   },
   "caquix-magician-mob": {
      "name": "Caquix Magician (mob)",
      "photos": [
         {
            "faces": [],
            "height": 1051,
            "name": "Shaiya0025.JPG",
            "slug": "shaiya0025-jpg",
            "src": "/static/_gallery/albums/caquix-magician-mob/1600x871_shaiya0025-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/caquix-magician-mob/1024x557_shaiya0025-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/caquix-magician-mob/1600x871_shaiya0025-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/caquix-magician-mob/500x272_shaiya0025-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/caquix-magician-mob/800x435_shaiya0025-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/caquix-magician-mob/500x272_shaiya0025-jpg.jpg",
            "width": 1930
         },
         {
            "faces": [],
            "height": 1051,
            "name": "Shaiya0026.JPG",
            "slug": "shaiya0026-jpg",
            "src": "/static/_gallery/albums/caquix-magician-mob/1600x871_shaiya0026-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/caquix-magician-mob/1024x557_shaiya0026-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/caquix-magician-mob/1600x871_shaiya0026-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/caquix-magician-mob/500x272_shaiya0026-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/caquix-magician-mob/800x435_shaiya0026-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/caquix-magician-mob/500x272_shaiya0026-jpg.jpg",
            "width": 1930
         }
      ],
      "slug": "caquix-magician-mob",
      "src": "/static/_gallery/albums/caquix-magician-mob/500x272_shaiya0025-jpg.jpg"
   },
   "cherubim-mob": {
      "name": "Cherubim (mob)",
      "photos": [
         {
            "faces": [],
            "height": 1051,
            "name": "Shaiya0059.JPG",
            "slug": "shaiya0059-jpg",
            "src": "/static/_gallery/albums/cherubim-mob/1600x871_shaiya0059-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/cherubim-mob/1024x557_shaiya0059-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/cherubim-mob/1600x871_shaiya0059-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/cherubim-mob/500x272_shaiya0059-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/cherubim-mob/800x435_shaiya0059-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/cherubim-mob/500x272_shaiya0059-jpg.jpg",
            "width": 1930
         },
         {
            "faces": [],
            "height": 1051,
            "name": "Shaiya0060.JPG",
            "slug": "shaiya0060-jpg",
            "src": "/static/_gallery/albums/cherubim-mob/1600x871_shaiya0060-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/cherubim-mob/1024x557_shaiya0060-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/cherubim-mob/1600x871_shaiya0060-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/cherubim-mob/500x272_shaiya0060-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/cherubim-mob/800x435_shaiya0060-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/cherubim-mob/500x272_shaiya0060-jpg.jpg",
            "width": 1930
         },
         {
            "faces": [],
            "height": 1051,
            "name": "Shaiya0061.JPG",
            "slug": "shaiya0061-jpg",
            "src": "/static/_gallery/albums/cherubim-mob/1600x871_shaiya0061-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/cherubim-mob/1024x557_shaiya0061-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/cherubim-mob/1600x871_shaiya0061-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/cherubim-mob/500x272_shaiya0061-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/cherubim-mob/800x435_shaiya0061-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/cherubim-mob/500x272_shaiya0061-jpg.jpg",
            "width": 1930
         },
         {
            "faces": [],
            "height": 1051,
            "name": "Shaiya0062.JPG",
            "slug": "shaiya0062-jpg",
            "src": "/static/_gallery/albums/cherubim-mob/1600x871_shaiya0062-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/cherubim-mob/1024x557_shaiya0062-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/cherubim-mob/1600x871_shaiya0062-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/cherubim-mob/500x272_shaiya0062-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/cherubim-mob/800x435_shaiya0062-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/cherubim-mob/500x272_shaiya0062-jpg.jpg",
            "width": 1930
         },
         {
            "faces": [],
            "height": 1051,
            "name": "Shaiya0063.JPG",
            "slug": "shaiya0063-jpg",
            "src": "/static/_gallery/albums/cherubim-mob/1600x871_shaiya0063-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/cherubim-mob/1024x557_shaiya0063-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/cherubim-mob/1600x871_shaiya0063-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/cherubim-mob/500x272_shaiya0063-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/cherubim-mob/800x435_shaiya0063-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/cherubim-mob/500x272_shaiya0063-jpg.jpg",
            "width": 1930
         },
         {
            "faces": [],
            "height": 1051,
            "name": "Shaiya0064.JPG",
            "slug": "shaiya0064-jpg",
            "src": "/static/_gallery/albums/cherubim-mob/1600x871_shaiya0064-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/cherubim-mob/1024x557_shaiya0064-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/cherubim-mob/1600x871_shaiya0064-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/cherubim-mob/500x272_shaiya0064-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/cherubim-mob/800x435_shaiya0064-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/cherubim-mob/500x272_shaiya0064-jpg.jpg",
            "width": 1930
         },
         {
            "faces": [],
            "height": 1051,
            "name": "Shaiya0065.JPG",
            "slug": "shaiya0065-jpg",
            "src": "/static/_gallery/albums/cherubim-mob/1600x871_shaiya0065-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/cherubim-mob/1024x557_shaiya0065-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/cherubim-mob/1600x871_shaiya0065-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/cherubim-mob/500x272_shaiya0065-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/cherubim-mob/800x435_shaiya0065-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/cherubim-mob/500x272_shaiya0065-jpg.jpg",
            "width": 1930
         }
      ],
      "slug": "cherubim-mob",
      "src": "/static/_gallery/albums/cherubim-mob/500x272_shaiya0059-jpg.jpg"
   },
   "drakenguardianchief-mob": {
      "name": "DrakenGuardianChief (mob)",
      "photos": [
         {
            "faces": [],
            "height": 1051,
            "name": "Shaiya0054.JPG",
            "slug": "shaiya0054-jpg",
            "src": "/static/_gallery/albums/drakenguardianchief-mob/1600x871_shaiya0054-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/drakenguardianchief-mob/1024x557_shaiya0054-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/drakenguardianchief-mob/1600x871_shaiya0054-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/drakenguardianchief-mob/500x272_shaiya0054-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/drakenguardianchief-mob/800x435_shaiya0054-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/drakenguardianchief-mob/500x272_shaiya0054-jpg.jpg",
            "width": 1930
         },
         {
            "faces": [],
            "height": 1051,
            "name": "Shaiya0056.JPG",
            "slug": "shaiya0056-jpg",
            "src": "/static/_gallery/albums/drakenguardianchief-mob/1600x871_shaiya0056-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/drakenguardianchief-mob/1024x557_shaiya0056-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/drakenguardianchief-mob/1600x871_shaiya0056-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/drakenguardianchief-mob/500x272_shaiya0056-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/drakenguardianchief-mob/800x435_shaiya0056-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/drakenguardianchief-mob/500x272_shaiya0056-jpg.jpg",
            "width": 1930
         },
         {
            "faces": [],
            "height": 1051,
            "name": "Shaiya0057.JPG",
            "slug": "shaiya0057-jpg",
            "src": "/static/_gallery/albums/drakenguardianchief-mob/1600x871_shaiya0057-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/drakenguardianchief-mob/1024x557_shaiya0057-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/drakenguardianchief-mob/1600x871_shaiya0057-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/drakenguardianchief-mob/500x272_shaiya0057-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/drakenguardianchief-mob/800x435_shaiya0057-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/drakenguardianchief-mob/500x272_shaiya0057-jpg.jpg",
            "width": 1930
         },
         {
            "faces": [],
            "height": 1051,
            "name": "Shaiya0058.JPG",
            "slug": "shaiya0058-jpg",
            "src": "/static/_gallery/albums/drakenguardianchief-mob/1600x871_shaiya0058-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/drakenguardianchief-mob/1024x557_shaiya0058-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/drakenguardianchief-mob/1600x871_shaiya0058-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/drakenguardianchief-mob/500x272_shaiya0058-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/drakenguardianchief-mob/800x435_shaiya0058-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/drakenguardianchief-mob/500x272_shaiya0058-jpg.jpg",
            "width": 1930
         }
      ],
      "slug": "drakenguardianchief-mob",
      "src": "/static/_gallery/albums/drakenguardianchief-mob/500x272_shaiya0054-jpg.jpg"
   },
   "hiiv-mount": {
      "name": "Hiiv (mount)",
      "photos": [
         {
            "faces": [],
            "height": 1051,
            "name": "Shaiya0125.JPG",
            "slug": "shaiya0125-jpg",
            "src": "/static/_gallery/albums/hiiv-mount/1600x871_shaiya0125-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/hiiv-mount/1024x557_shaiya0125-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/hiiv-mount/1600x871_shaiya0125-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/hiiv-mount/500x272_shaiya0125-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/hiiv-mount/800x435_shaiya0125-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/hiiv-mount/500x272_shaiya0125-jpg.jpg",
            "width": 1930
         },
         {
            "faces": [],
            "height": 1051,
            "name": "Shaiya0127.JPG",
            "slug": "shaiya0127-jpg",
            "src": "/static/_gallery/albums/hiiv-mount/1600x871_shaiya0127-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/hiiv-mount/1024x557_shaiya0127-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/hiiv-mount/1600x871_shaiya0127-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/hiiv-mount/500x272_shaiya0127-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/hiiv-mount/800x435_shaiya0127-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/hiiv-mount/500x272_shaiya0127-jpg.jpg",
            "width": 1930
         },
         {
            "faces": [],
            "height": 1051,
            "name": "Shaiya0128.JPG",
            "slug": "shaiya0128-jpg",
            "src": "/static/_gallery/albums/hiiv-mount/1600x871_shaiya0128-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/hiiv-mount/1024x557_shaiya0128-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/hiiv-mount/1600x871_shaiya0128-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/hiiv-mount/500x272_shaiya0128-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/hiiv-mount/800x435_shaiya0128-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/hiiv-mount/500x272_shaiya0128-jpg.jpg",
            "width": 1930
         },
         {
            "faces": [],
            "height": 1051,
            "name": "Shaiya0129.JPG",
            "slug": "shaiya0129-jpg",
            "src": "/static/_gallery/albums/hiiv-mount/1600x871_shaiya0129-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/hiiv-mount/1024x557_shaiya0129-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/hiiv-mount/1600x871_shaiya0129-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/hiiv-mount/500x272_shaiya0129-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/hiiv-mount/800x435_shaiya0129-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/hiiv-mount/500x272_shaiya0129-jpg.jpg",
            "width": 1930
         }
      ],
      "slug": "hiiv-mount",
      "src": "/static/_gallery/albums/hiiv-mount/500x272_shaiya0125-jpg.jpg"
   },
   "lepisma-mount": {
      "name": "Lepisma (mount)",
      "photos": [
         {
            "faces": [],
            "height": 1051,
            "name": "Shaiya0130.JPG",
            "slug": "shaiya0130-jpg",
            "src": "/static/_gallery/albums/lepisma-mount/1600x871_shaiya0130-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/lepisma-mount/1024x557_shaiya0130-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/lepisma-mount/1600x871_shaiya0130-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/lepisma-mount/500x272_shaiya0130-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/lepisma-mount/800x435_shaiya0130-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/lepisma-mount/500x272_shaiya0130-jpg.jpg",
            "width": 1930
         },
         {
            "faces": [],
            "height": 1051,
            "name": "Shaiya0131.JPG",
            "slug": "shaiya0131-jpg",
            "src": "/static/_gallery/albums/lepisma-mount/1600x871_shaiya0131-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/lepisma-mount/1024x557_shaiya0131-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/lepisma-mount/1600x871_shaiya0131-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/lepisma-mount/500x272_shaiya0131-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/lepisma-mount/800x435_shaiya0131-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/lepisma-mount/500x272_shaiya0131-jpg.jpg",
            "width": 1930
         },
         {
            "faces": [],
            "height": 1051,
            "name": "Shaiya0132.JPG",
            "slug": "shaiya0132-jpg",
            "src": "/static/_gallery/albums/lepisma-mount/1600x871_shaiya0132-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/lepisma-mount/1024x557_shaiya0132-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/lepisma-mount/1600x871_shaiya0132-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/lepisma-mount/500x272_shaiya0132-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/lepisma-mount/800x435_shaiya0132-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/lepisma-mount/500x272_shaiya0132-jpg.jpg",
            "width": 1930
         },
         {
            "faces": [],
            "height": 1051,
            "name": "Shaiya0133.JPG",
            "slug": "shaiya0133-jpg",
            "src": "/static/_gallery/albums/lepisma-mount/1600x871_shaiya0133-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/lepisma-mount/1024x557_shaiya0133-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/lepisma-mount/1600x871_shaiya0133-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/lepisma-mount/500x272_shaiya0133-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/lepisma-mount/800x435_shaiya0133-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/lepisma-mount/500x272_shaiya0133-jpg.jpg",
            "width": 1930
         }
      ],
      "slug": "lepisma-mount",
      "src": "/static/_gallery/albums/lepisma-mount/500x272_shaiya0130-jpg.jpg"
   },
   "mammoth-mount": {
      "name": "Mammoth (mount)",
      "photos": [
         {
            "faces": [],
            "height": 1051,
            "name": "Shaiya0134.JPG",
            "slug": "shaiya0134-jpg",
            "src": "/static/_gallery/albums/mammoth-mount/1600x871_shaiya0134-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/mammoth-mount/1024x557_shaiya0134-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/mammoth-mount/1600x871_shaiya0134-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/mammoth-mount/500x272_shaiya0134-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/mammoth-mount/800x435_shaiya0134-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/mammoth-mount/500x272_shaiya0134-jpg.jpg",
            "width": 1930
         },
         {
            "faces": [],
            "height": 1051,
            "name": "Shaiya0135.JPG",
            "slug": "shaiya0135-jpg",
            "src": "/static/_gallery/albums/mammoth-mount/1600x871_shaiya0135-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/mammoth-mount/1024x557_shaiya0135-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/mammoth-mount/1600x871_shaiya0135-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/mammoth-mount/500x272_shaiya0135-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/mammoth-mount/800x435_shaiya0135-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/mammoth-mount/500x272_shaiya0135-jpg.jpg",
            "width": 1930
         },
         {
            "faces": [],
            "height": 1051,
            "name": "Shaiya0136.JPG",
            "slug": "shaiya0136-jpg",
            "src": "/static/_gallery/albums/mammoth-mount/1600x871_shaiya0136-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/mammoth-mount/1024x557_shaiya0136-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/mammoth-mount/1600x871_shaiya0136-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/mammoth-mount/500x272_shaiya0136-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/mammoth-mount/800x435_shaiya0136-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/mammoth-mount/500x272_shaiya0136-jpg.jpg",
            "width": 1930
         },
         {
            "faces": [],
            "height": 1051,
            "name": "Shaiya0137.JPG",
            "slug": "shaiya0137-jpg",
            "src": "/static/_gallery/albums/mammoth-mount/1600x871_shaiya0137-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/mammoth-mount/1024x557_shaiya0137-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/mammoth-mount/1600x871_shaiya0137-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/mammoth-mount/500x272_shaiya0137-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/mammoth-mount/800x435_shaiya0137-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/mammoth-mount/500x272_shaiya0137-jpg.jpg",
            "width": 1930
         },
         {
            "faces": [],
            "height": 1051,
            "name": "Shaiya0138.JPG",
            "slug": "shaiya0138-jpg",
            "src": "/static/_gallery/albums/mammoth-mount/1600x871_shaiya0138-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/mammoth-mount/1024x557_shaiya0138-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/mammoth-mount/1600x871_shaiya0138-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/mammoth-mount/500x272_shaiya0138-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/mammoth-mount/800x435_shaiya0138-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/mammoth-mount/500x272_shaiya0138-jpg.jpg",
            "width": 1930
         },
         {
            "faces": [],
            "height": 1051,
            "name": "Shaiya0140.JPG",
            "slug": "shaiya0140-jpg",
            "src": "/static/_gallery/albums/mammoth-mount/1600x871_shaiya0140-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/mammoth-mount/1024x557_shaiya0140-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/mammoth-mount/1600x871_shaiya0140-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/mammoth-mount/500x272_shaiya0140-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/mammoth-mount/800x435_shaiya0140-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/mammoth-mount/500x272_shaiya0140-jpg.jpg",
            "width": 1930
         },
         {
            "faces": [],
            "height": 1051,
            "name": "Shaiya0141.JPG",
            "slug": "shaiya0141-jpg",
            "src": "/static/_gallery/albums/mammoth-mount/1600x871_shaiya0141-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/mammoth-mount/1024x557_shaiya0141-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/mammoth-mount/1600x871_shaiya0141-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/mammoth-mount/500x272_shaiya0141-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/mammoth-mount/800x435_shaiya0141-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/mammoth-mount/500x272_shaiya0141-jpg.jpg",
            "width": 1930
         },
         {
            "faces": [],
            "height": 1051,
            "name": "Shaiya0143.JPG",
            "slug": "shaiya0143-jpg",
            "src": "/static/_gallery/albums/mammoth-mount/1600x871_shaiya0143-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/mammoth-mount/1024x557_shaiya0143-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/mammoth-mount/1600x871_shaiya0143-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/mammoth-mount/500x272_shaiya0143-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/mammoth-mount/800x435_shaiya0143-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/mammoth-mount/500x272_shaiya0143-jpg.jpg",
            "width": 1930
         }
      ],
      "slug": "mammoth-mount",
      "src": "/static/_gallery/albums/mammoth-mount/500x272_shaiya0134-jpg.jpg"
   },
   "tesinon-mob": {
      "name": "Tesinon (mob)",
      "photos": [
         {
            "faces": [],
            "height": 1051,
            "name": "Shaiya0034.JPG",
            "slug": "shaiya0034-jpg",
            "src": "/static/_gallery/albums/tesinon-mob/1600x871_shaiya0034-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/tesinon-mob/1024x557_shaiya0034-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/tesinon-mob/1600x871_shaiya0034-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/tesinon-mob/500x272_shaiya0034-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/tesinon-mob/800x435_shaiya0034-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/tesinon-mob/500x272_shaiya0034-jpg.jpg",
            "width": 1930
         },
         {
            "faces": [],
            "height": 1051,
            "name": "Shaiya0036.JPG",
            "slug": "shaiya0036-jpg",
            "src": "/static/_gallery/albums/tesinon-mob/1600x871_shaiya0036-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/tesinon-mob/1024x557_shaiya0036-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/tesinon-mob/1600x871_shaiya0036-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/tesinon-mob/500x272_shaiya0036-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/tesinon-mob/800x435_shaiya0036-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/tesinon-mob/500x272_shaiya0036-jpg.jpg",
            "width": 1930
         },
         {
            "faces": [],
            "height": 1051,
            "name": "Shaiya0042.JPG",
            "slug": "shaiya0042-jpg",
            "src": "/static/_gallery/albums/tesinon-mob/1600x871_shaiya0042-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/tesinon-mob/1024x557_shaiya0042-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/tesinon-mob/1600x871_shaiya0042-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/tesinon-mob/500x272_shaiya0042-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/tesinon-mob/800x435_shaiya0042-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/tesinon-mob/500x272_shaiya0042-jpg.jpg",
            "width": 1930
         },
         {
            "faces": [],
            "height": 1051,
            "name": "Shaiya0044.JPG",
            "slug": "shaiya0044-jpg",
            "src": "/static/_gallery/albums/tesinon-mob/1600x871_shaiya0044-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/tesinon-mob/1024x557_shaiya0044-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/tesinon-mob/1600x871_shaiya0044-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/tesinon-mob/500x272_shaiya0044-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/tesinon-mob/800x435_shaiya0044-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/tesinon-mob/500x272_shaiya0044-jpg.jpg",
            "width": 1930
         },
         {
            "faces": [],
            "height": 1051,
            "name": "Shaiya0046.JPG",
            "slug": "shaiya0046-jpg",
            "src": "/static/_gallery/albums/tesinon-mob/1600x871_shaiya0046-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/tesinon-mob/1024x557_shaiya0046-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/tesinon-mob/1600x871_shaiya0046-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/tesinon-mob/500x272_shaiya0046-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/tesinon-mob/800x435_shaiya0046-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/tesinon-mob/500x272_shaiya0046-jpg.jpg",
            "width": 1930
         },
         {
            "faces": [],
            "height": 1051,
            "name": "Shaiya0047.JPG",
            "slug": "shaiya0047-jpg",
            "src": "/static/_gallery/albums/tesinon-mob/1600x871_shaiya0047-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/tesinon-mob/1024x557_shaiya0047-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/tesinon-mob/1600x871_shaiya0047-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/tesinon-mob/500x272_shaiya0047-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/tesinon-mob/800x435_shaiya0047-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/tesinon-mob/500x272_shaiya0047-jpg.jpg",
            "width": 1930
         },
         {
            "faces": [],
            "height": 1051,
            "name": "Shaiya0049.JPG",
            "slug": "shaiya0049-jpg",
            "src": "/static/_gallery/albums/tesinon-mob/1600x871_shaiya0049-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/tesinon-mob/1024x557_shaiya0049-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/tesinon-mob/1600x871_shaiya0049-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/tesinon-mob/500x272_shaiya0049-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/tesinon-mob/800x435_shaiya0049-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/tesinon-mob/500x272_shaiya0049-jpg.jpg",
            "width": 1930
         },
         {
            "faces": [],
            "height": 1051,
            "name": "Shaiya0052.JPG",
            "slug": "shaiya0052-jpg",
            "src": "/static/_gallery/albums/tesinon-mob/1600x871_shaiya0052-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/tesinon-mob/1024x557_shaiya0052-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/tesinon-mob/1600x871_shaiya0052-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/tesinon-mob/500x272_shaiya0052-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/tesinon-mob/800x435_shaiya0052-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/tesinon-mob/500x272_shaiya0052-jpg.jpg",
            "width": 1930
         }
      ],
      "slug": "tesinon-mob",
      "src": "/static/_gallery/albums/tesinon-mob/500x272_shaiya0034-jpg.jpg"
   },
   "valak-mob": {
      "name": "Valak (mob)",
      "photos": [
         {
            "faces": [],
            "height": 1051,
            "name": "Shaiya0015.JPG",
            "slug": "shaiya0015-jpg",
            "src": "/static/_gallery/albums/valak-mob/1600x871_shaiya0015-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/valak-mob/1024x557_shaiya0015-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/valak-mob/1600x871_shaiya0015-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/valak-mob/500x272_shaiya0015-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/valak-mob/800x435_shaiya0015-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/valak-mob/500x272_shaiya0015-jpg.jpg",
            "width": 1930
         }
      ],
      "slug": "valak-mob",
      "src": "/static/_gallery/albums/valak-mob/500x272_shaiya0015-jpg.jpg"
   }
};